import React, { useState } from "react";
import "./Home.css";
//COMPONENTS
import SectionHeader from "../../individual/SectionHeader/SectionHeader";
import PageInfoBox from "../../individual/PageInfoBox/PageInfoBox";
import DirectorCard from "../../individual/DirectorCard/DirectorCard";
import ReactPlayer from "react-player";
import useIsMobile from "../../../custom-hooks/useIsMobile";

//IMAGES

const Home = ({ directorCard, data }) => {
  const [controls, setControls] = useState(false);
  const isMobile = useIsMobile();

  const transformed = Object.values(data?.senseFronteres?.videoArray || {})
    .filter((url) => url && url.trim() !== "")
    .map((url) => {
      if (!url.includes("/upload/")) return url;

      const transformations = "f_auto";
      return url.replace("/upload/", `/upload/${transformations}/`);
    });

  const transformedLogo = data?.senseFronteres?.home?.image[0]?.url.replace(
    `/upload/q_auto,f_auto,fl_lossy,dpr_auto,c_scale/`
  );
  const transformedDirectorCard =
    data?.senseFronteres?.directorCard?.image[0]?.url.replace(
      `/upload/f_auto,q_auto,w_auto,fl_lossy,dpr_auto`
    );
  return (
    <>
      <div className="home__container">
        <img
          src={transformedLogo}
          style={{
            width: "70%",
            height: "100%",
            objectFit: "contain",
          }}
          alt="logo"
        />
      </div>
      <div className="home__info-box__container">
        {data !== undefined && (
          <SectionHeader
            title={
              data &&
              data.sectionHeader &&
              data.sectionHeader.senseFronteres &&
              data.sectionHeader.senseFronteres.senseFronteresSection &&
              data.sectionHeader.senseFronteres.senseFronteresSection.title
                ? data.sectionHeader.senseFronteres.senseFronteresSection.title
                : ""
            }
            text={
              data &&
              data.sectionHeader &&
              data.sectionHeader.senseFronteres &&
              data.sectionHeader.senseFronteres.senseFronteresSection &&
              data.sectionHeader.senseFronteres.senseFronteresSection.subtitle
                ? data.sectionHeader.senseFronteres.senseFronteresSection
                    .subtitle
                : ""
            }
            width={"150px"}
          />
        )}
        {data !== undefined && <PageInfoBox margin={"50px"} data={data} />}
        {data !== undefined && (
          <DirectorCard
            image={transformedDirectorCard}
            title={data?.senseFronteres?.directorCard?.title}
            text={data?.senseFronteres?.directorCard?.text}
            info={data?.senseFronteres?.directorCard?.quote}
            director={directorCard}
            data={data}
          />
        )}
        {data !== undefined && (
          <div className="home__videos">
            <SectionHeader
              title={data?.sectionHeader?.senseFronteres?.videos?.title}
              text={data?.sectionHeader?.senseFronteres?.videos?.subtitle}
              width={"250px"}
            />
            <div className="home__videos__wrapper">
              {transformed?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      paddingBottom: "56.25%",
                      height: 0,
                      overflow: "hidden",
                      borderRadius: "25px",
                    }}
                    onClick={() => {
                      setControls(true);
                    }}
                  >
                    <ReactPlayer
                      url={item}
                      light={true} // Utilitza el primer frame del vídeo com a miniatura
                      alt=""
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50px",
                      }}
                      width={"100%"}
                      height={"100%"}
                      controls={isMobile ? controls : true}
                      config={{
                        file: {
                          attributes: {
                            style: {
                              objectFit: "cover",
                              height: "100%",
                              width: "100%",
                            },
                          },
                        },
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
